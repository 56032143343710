/* Home.css */
:root {
    --primary-bg: #000000;
}

.home {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Intro Section */
.intro {
    margin-bottom: 60px;
}

.intro h1 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #ffffff;
}

.subtitle {
    font-size: 20px;
    color: #999999;
    margin-bottom: 30px;
}

.bio {
    font-size: 16px;
    line-height: 1.6;
    color: #999999;
}

.bio b {
    color: #ffffff;
    font-weight: 600;
}

.bio p {
    margin-bottom: 20px;
}

.bio ul {
    margin-left: 20px;
    margin-top: 10px;
    list-style-type: none;
}

.bio li {
    margin-bottom: 10px;
    position: relative;
}

.bio li::before {
    content: "•";
    color: #999999;
    position: absolute;
    left: -15px;
}

.bio a, 
.email-link {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.bio a:hover, 
.email-link:hover {
    color: #ff6b6b;
}

/* Snapshots Section */
.snapshots {
    margin-top: 100px;
    position: relative;
}

.snapshots h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 100px;

    color: #ffffff;
}

.scroll-container {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.scroll-container::before,
.scroll-container::after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 50px;
    z-index: 2;
    pointer-events: none;
}

.scroll-container::before {
    left: 0;
    background: linear-gradient(to right, #000000 0%, transparent 100%);
    opacity: 0.3;
}

.scroll-container::after {
    right: 0;
    background: linear-gradient(to left, #000000 0%, transparent 100%);
    opacity: 0.3;
}
.image-grid {
    display: flex;
    gap: 24px;
    padding: 20px 0;
    width: max-content;  /* Ensures container grows with images */
    animation: scroll 50s linear infinite; /* Adjust duration for smooth scrolling */
}

.image-grid:hover {
    animation-play-state: paused;
}

.snapshot-img {
    width: 600px;  /* Increased from 400px */
    height: 400px; /* Increased from 300px */
    object-fit: cover;
    border-radius: 12px;
    transition: transform 0.3s ease;
}

.snapshot-img:hover {
    transform: scale(1.05);
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
/* Large screens */
@media (min-width: 1400px) {
    .home {
        max-width: 1400px;
    }
}

/* Large Tablet styles */
@media (max-width: 1024px) {
    .snapshot-img {
        width: 350px;
        height: 250px;
    }
}

/* Tablet styles */
@media (max-width: 768px) {
    .intro h1 {
        font-size: 36px;
    }

    .subtitle {
        font-size: 18px;
    }

    .bio {
        font-size: 15px;
    }

    .snapshot-img {
        width: 300px;
        height: 200px;
    }
    
    .image-grid {
        gap: 16px;
    }

    .snapshots h2 {
        font-size: 28px;
    }
}

/* Mobile styles */
@media (max-width: 480px) {
    .home {
        padding: 15px;
    }

    .intro h1 {
        font-size: 32px;
    }

    .subtitle {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .bio {
        font-size: 14px;
    }

    .snapshot-img {
        width: 250px;
        height: 180px;
    }
    
    .image-grid {
        gap: 12px;
    }

    .snapshots {
        margin-top: 40px;
    }

    .snapshots h2 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .bio ul {
        margin-left: 15px;
    }

    .scroll-container::before,
    .scroll-container::after {
        width: 30px;
    }
}