/* Projects.css */
.projects {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.projects h1 {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 16px;
    color: #ffffff;
}

.projects-subtitle {
    font-size: 18px;
    color: #999999;
    margin-bottom: 48px;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 24px;
    margin-top: 32px;
}

.project-card-link {
    text-decoration: none;
    color: inherit;
    display: block;
}

.project-card {
    background: #1a1a1a;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.project-image {
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
}

.project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.project-card:hover .project-image img {
    transform: scale(1.05);
}

.project-content {
    padding: 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.project-category {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #999999;
    font-size: 14px;
    margin-bottom: 12px;
}

.project-category svg {
    color: #666666;
}

.project-content h3 {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 12px;
}

.project-content p {
    color: #999999;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.project-link {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #ff6b6b;
    font-size: 14px;
    font-weight: 500;
    transition: transform 0.2s ease;
}

.project-card:hover .project-link {
    transform: translateX(5px);
}

/* Tablet Styles */
@media (max-width: 1024px) {
    .projects-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Mobile Styles */
@media (max-width: 768px) {
    .projects {
        padding: 16px;
    }

    .projects h1 {
        font-size: 36px;
    }

    .projects-subtitle {
        font-size: 16px;
        margin-bottom: 32px;
    }

    .projects-grid {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .project-content h3 {
        font-size: 20px;
    }

    .project-content p {
        font-size: 14px;
    }
}