/* Sidebar.css */
.sidebar {
    width: 240px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #1a1a1a;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    z-index: 1000;
}

.profile-image-container {
    width: 120px;
    height: 120px;
    margin: 20px auto 30px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #333333;
    transition: all 0.3s ease;
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.nav-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.nav-link {
    display: flex;
    align-items: center;
    padding: 12px 15px;
    text-decoration: none;
    color: #a0a0a0;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.nav-link:hover {
    background-color: #2a2a2a;
    color: #ffffff;
}

.nav-link.active {
    background-color: #2a2a2a;
    color: #ffffff;
}

.nav-link svg {
    margin-right: 12px;
}

.nav-text {
    font-size: 16px;
    font-weight: 500;
}

.social-links {
    margin-top: auto;
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px 0;
}

.social-link {
    color: #a0a0a0;
    transition: color 0.3s ease;
}

.social-link:hover {
    color: #ffffff;
}

/* Mobile Menu Button */
.mobile-menu-btn {
    display: none;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1001;
    background: #1a1a1a;
    border: none;
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    color: white;
}

/* Tablet styles */
@media (max-width: 768px) {
    .sidebar {
        width: 60px;
        padding: 15px 10px;
    }

    .profile-image-container {
        width: 40px;
        height: 40px;
        margin: 10px auto 20px;
        border-width: 2px;
    }

    .nav-text {
        display: none;
    }

    .nav-link {
        justify-content: center;
        padding: 12px;
    }

    .nav-link svg {
        margin-right: 0;
    }

    .social-links {
        flex-direction: column;
        gap: 15px;
    }
}

/* Mobile styles */
@media (max-width: 480px) {
    .sidebar {
        transform: translateX(-100%);
        width: 100%;
        padding: 20px;
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .mobile-menu-btn {
        display: block;
    }

    .profile-image-container {
        width: 80px;
        height: 80px;
    }

    .nav-text {
        display: block;
    }

    .nav-link {
        justify-content: flex-start;
        padding: 15px;
    }

    .nav-link svg {
        margin-right: 15px;
    }

    .social-links {
        flex-direction: row;
        justify-content: center;
        gap: 30px;
    }
}