:root {
  --primary-bg: #000000;
  --sidebar-bg: #111111;
  --text-primary: #ffffff;
  --text-secondary: #999999;
  --accent-color: #666666;
  --hover-color: #333333;
  --sidebar-width: 240px;
  --sidebar-mobile-width: 60px;
}

/* Reset Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body */
body {
  background-color: var(--primary-bg);
  color: var(--text-primary);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overflow-x: hidden;
}

/* App Layout */
.app {
  display: flex;
  flex-direction: column; /* Ensures the main-content & footer are stacked */
  min-height: 100vh; /* Makes sure the footer is pushed to the bottom */
}

/* Sidebar */
.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: var(--sidebar-width);
  height: 100vh;
  background-color: var(--sidebar-bg);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

/* Main Content */
.main-content {
  flex: 1; /* Expands to take remaining space */
  padding: 2rem;
  margin-left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width));
  max-width: calc(100% - var(--sidebar-width));
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

/* Navigation Links */
.nav-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  color: var(--text-secondary);
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.nav-link:hover {
  background-color: var(--hover-color);
  color: var(--text-primary);
}

.nav-link.active {
  background-color: var(--hover-color);
  color: var(--text-primary);
}

/* Social Links */
.social-links {
  margin-top: auto;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-bottom: 2rem;
}

.social-link {
  color: var(--text-secondary);
  transition: color 0.3s ease;
}

.social-link:hover {
  color: var(--text-primary);
}

/* Mobile Menu Button */
.mobile-menu-btn {
  display: none;
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1001;
  background: var(--sidebar-bg);
  border: none;
  padding: 0.5rem;
  border-radius: 6px;
  cursor: pointer;
  color: var(--text-primary);
}

/* Footer */
.footer {
  text-align: center;
  padding: 20px;
  background-color: var(--primary-bg); /* Same as sidebar */
  color: var(--text-secondary);
  font-size: 14px;
  width: 100%;
  margin-top: auto; /* Pushes footer to bottom */
}

.footer-links {
  margin-top: 10px;
}

.footer-links a {
  color: var(--accent-color);
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: var(--text-primary);
}

/* Tablet Styles */
@media (max-width: 768px) {
  .sidebar {
    width: var(--sidebar-mobile-width);
    padding: 1rem 0.5rem;
  }

  .main-content {
    margin-left: var(--sidebar-mobile-width);
    width: calc(100% - var(--sidebar-mobile-width));
    max-width: calc(100% - var(--sidebar-mobile-width));
    padding: 1.5rem;
  }

  .nav-link span {
    display: none;
  }

  .nav-link {
    justify-content: center;
    padding: 0.75rem;
  }

  .social-links {
    flex-direction: column;
    gap: 0.75rem;
  }
}

/* Mobile Styles */
@media (max-width: 480px) {
  .mobile-menu-btn {
    display: block;
  }

  .sidebar {
    transform: translateX(-100%);
    width: var(--sidebar-width);
    padding: 1.5rem;
  }

  .sidebar.open {
    transform: translateX(0);
  }

  .main-content {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
    padding: 1rem;
    padding-top: 4rem;
  }

  .nav-link span {
    display: inline;
  }

  .social-links {
    flex-direction: row;
    gap: 1rem;
  }
}
